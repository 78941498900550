.prizes-page {
  background-color: $mern-primary;
  color: $dac-beige;
  img{
    height: 300px;
  }
  & > .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .heading-1{
    color: $dac-yellow;
    padding-top: 20px;
    padding-left: 10px;
  }
  .detail{
    padding-left: 15px;
  }
  .columns{
    align-items: center;
  }
  @media all and (max-width: 768px){
    > .container .columns:nth-child(2) {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

}
