@font-face {
  font-family: "Ornito";
  src: url("../assets/fonts/Ornito-Regular.otf");
}
@font-face {
  font-family: "Ornito";
  src: url("../assets/fonts/Ornito-Bold.otf");
  font-weight: bold;
}
@font-face {
  font-family: "Ornito";
  src: url("../assets/fonts/Ornito-DemiBold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Ornito";
  src: url("../assets/fonts/Ornito-light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Ornito";
  src: url("../assets/fonts/Ornito-Xlight.otf");
  font-weight: 200;
}

@import 'bulma-config';
@import '~bulma';

@import 'variables';
@import 'general';
@import 'modifiers';
@import 'components';

@import 'main';
@import 'navigation';
@import 'footer';

@import 'welcome';
@import 'login';
@import 'register';
@import 'showcase';
@import 'rules';
@import 'prizes';
@import 'howtoenter';
@import 'enter';
@import 'brief';

