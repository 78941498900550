.enter-page {
  background-color: $dac-beige;
  color: $dac-red;
  a{
    color: $dac-red;
    text-decoration: underline;
    font-weight: 600;
  }
  .container{
    padding: 5% 15%;
  }
  .heading-1{
    color: $dac-yellow;
  }
  .sub {
    color: $dac-red;
    font-weight: 600;
    margin-bottom: 50px;
  }
  input.input{
    font-size: 1.2em;
    border-radius: 30px;
    height: 4rem;
    padding: 20px;
  }

  input.input::placeholder {
    color: #626262;
    font-weight: 600;
  }
  .spaced-div{
    align-items: center;
    display:flex;
    justify-content: space-between;
    label{
      display: flex;
      input[type=checkbox]{
        margin-right: 5px;
      }
    }
  }
  .select:not(.is-multiple){
    height: 4rem;
    select{
      height: 4rem;
    }
  }
  .file-cta{
    background-color: $dac-yellow;
    color: $dac-red;
    border-radius: 20px;
    font-weight: 600;
    padding: 20px;
    width: 100%;
    &.active{
      background-color: #7FC241;
      color: white;
    }
  }
  .file-icon{
    margin: 10px;
  }
  .design-title{
    color: $dac-red;
    font-weight: 600;
  }
  .file{
    display: inline-block;
  }
  textarea{
    padding: 20px;
    border-radius: 20px;
    margin: 20px 0;
    font-size: 1.2em;
  }
  @media all and (max-width: 1024px){
    .container{
      padding: 5%;
    }
  }
  //.resume_upload{
  //  .file-cta{
  //    width: 100;
  //  }
  //}
  //.motivation_upload{
  //  .file-cta{
  //    min-width: ;
  //  }
  //}
  //.copyright_upload{
  //  .file-cta{
  //    min-width: ;
  //  }
  //}

}
