.brief-page {
  background: url("../assets/images/designbrief-bg.png") bottom no-repeat;
  background-size: cover;
  text-align: center;
  color: $dac-red;
  .title{
    color: $dac-red;
  }
  & > .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
