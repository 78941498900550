.dropdown {
  min-width: 20rem;
  padding: .5rem 0;
  position: absolute;
  right: .75rem;
  top: 4rem;

  .dropdown-list {
    width: 100%;
  }

  .dropdown-header {
    padding: 0 1rem;
    text-align: center;
  }

  .dropdown-item {
    font-size: 1rem;
    font-weight: $weight-semibold;
    padding: 0 1rem;
    text-align: left;

    a {
      color: $black;
      display: block;
      padding: .5rem 1rem;
      width: 100%;
    }

    a:hover {
      background-color: $mern-primary;
      color: $white;
      cursor: pointer;
    }
  }

  .dropdown-separator {
    margin: .5rem 0;
  }
}

.dropdown-caret {
  border: 4px solid $white;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  margin-left: .25rem;
}
