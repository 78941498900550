.is-fullscreen {
  min-height: calc(100vh - 70px);
}

.is-fullpage {
  min-height: 100vh;
}

.is-fullwidth {
  width: 100%;
}

.separator {
  margin: 0 -1.25rem 1.25rem;
}

.has-space-below {
  margin-bottom: 1.5rem;
}

