
html {
  background-color: $whitesmoke;
  font-size: 12px;
}
a {
  color: $info;
}
body{
  font-size: 1.2em;
}

.title {
  font-weight: 600;
  color: white
}

.section {
  background-color: transparent;
}

.box {
  border-radius: 2px;
}

.select::after {
  border-color: $dark-grey;
}

.field.has-help {
  height: 67px;
}

.card-footer-item {
  cursor: pointer;
  &:hover {
    background-color: $lightest-grey;
  }
}

// Bug in production
.select select {
  padding-right: 2.5em;
}

.cta-btn{
  background: $dac-yellow;
  color: white;
  text-transform: uppercase;
  border-radius: 20px;
  border: 0;
  transition: color 250ms ease;
  margin: 0 20px;
  &:hover{
    color: $mern-hover;
  }
}
.hero-cta{
  background: $dac-beige;
  color: $dac-red;
  cursor: pointer;
  border-radius: 20px;
  display: inline-flex;
  padding: 5px 20px;
  align-items: center;
  font-weight: bold;
  &.alt{
    background: $dac-red;
    color: $dac-beige;
  }
}
.hero-heading{
  font-size: clamp(3rem, 2.5091rem + 4.1818vw, 7rem);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0 !important;

}

.heading-1{
  font-size: clamp(3rem, 2.5091rem + 4.1818vw, 5rem);
  font-weight: 600;
}

.heading-2{
  font-size: clamp(3rem, 2.5091rem + 4.1818vw, 4rem);
  font-weight: 600;
}
@media all and (min-width: 1024px) {
  .pl-10-desktop{
    padding-left: 10%;
  }

}

.status-page{
  background: $dac-beige;
  text-align: center;
  & > .container{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .heading-1{
    color: $dac-yellow;
  }
  p{
    color: $dac-red;
    font-weight: 600;
  }
}
.file-cta{
  &:not(.active) {

    .uploaded-icon {
      display: none;
    }
  }
  &.active {

    .upload-icon{
      display: none;
    }
  }
}
