.welcome-page {
  text-align: center;
  overflow: hidden;
  background: #643230;

  .dac_hero{
    background: url("../assets/images/home-hero.png") bottom right no-repeat;
    background-size: cover;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 20%;

    .heading-2{
      color: $dac-red;
      font-size: 3rem;
      padding: 20px 0;
    }
    .container{
      z-index: 2;
    }
    .hero-cta{
      background-color: $dac-red;
      color: $dac-beige;
      svg path{
        fill: $dac-beige;
      }
      @media all and (max-width: 768px) {
        font-size: 1.6rem;
      }
    }
    .hero-content{
      color: $dac-red;
      font-weight: 600;
      width: 55%;
      font-size: 1.6rem;
    }
    @media all and (max-width: 768px) {
      background-position: 40% 80%;
      padding: 5%;
      .container{
        padding: 20% 0;
      }
      .hero-content{
        width: 100%;
      }
    }
  }
  .dac_basics{
    color: $dac-beige;
    background-color: $dac-red;
    position: relative;
    display: flex;
    align-items: center;

    strong{
      color: #ECAD00;
    }
    .column{
      z-index: 2;
    }
    .heading-1{
      color: #EBD9BC;
    }
    .subheading{
      color: #ECAD00;

    }
    .basics_svg{
      width: 100%;
    }
    @media all and (max-width: 1024px) {

      .basics_svg{
        margin-left: unset;
        width: 100%;
        height: auto;
      }
      .container{
        padding: 5%;
      }
      .section-divider{
        display: none;
      }
      .section-divider-mobile{
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100vw;
        top: -50px;

      }
    }
  }
  .dac_theme{
    z-index: 1;
    position: relative;
    background: url('../assets/images/home-theme-section.svg');
    background-size: cover;
    margin-top: -30vw;
    padding-top: 20vw;
    color: #EBD9BC;
    background-color: $mern-primary;


    .heading-2, .heading-1{
      color: #F7B21F;
    }
    @media all and (max-width: 1024px) {
      margin-top: 0;
      padding-top: 20vh;
      .container{
        padding: 5%;
      }
    }
  }
  .dac_prices{

      display: flex;
      align-items: center;
      flex-direction: column;
    & > .container {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    color: #EBD9BC;
    background-color: #643230;
    .prizes-bg{
      width: 100%;
    }
    //padding-bottom: 220px;

    .heading-2, .heading-1{
      color: #F7B21F;
    }
    @media all and (max-width: 1024px) {
      .prizes-bg{
        position: relative;
      }
      .prizes-container{
        padding: 5%;
      }
    }
  }
}

body.hide_footer{
  .dac_amaze, .footer-wrapper{
    display: none;
  }
  .is-fullpage{
    min-height: calc(100vh - 70px);
    &.page{
      padding-bottom: initial;
    }
  }
}
.dac_amaze{
  background-color: $dac-yellow;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  color: $dac-red;
  .title{
    color: $dac-red;
  }
  .primary-bg{
    position: absolute;
    left: -5px;
    bottom: 0;
    max-width: none;
    height: 140vh;
    max-height: 1000px;
    width: 101%;
    z-index: 1;
  }
  .abstract-1{
    z-index: 2;
    position: absolute;
    left: -20%;
    bottom: -10vh;
    max-width: 800px;
  }
  .abstract-2 {
    position: absolute;
    z-index: 3;
    top: -70%;
    right: -90px;
    width: 310px;
  }
  .abstract-3{
    position: absolute;
    z-index: 0;
    left: 0;
    top: -33%;
    width: 200px
  }
  .enter-button {
    background: $dac-red;
    border-radius: 20px;
    color: #EBD9BC;
    border-color: $dac-red;
    padding: 0 20px;
  }

  .register-button {
    background: transparent;
    border-radius: 20px;
    color: $dac-red;
    border-color: $dac-red;
    padding: 0 20px;
  }
  .enter-button, .register-button{
    font-size: 1.2em;
  }

  .primary-bg-mobile{
    display: none;
    position: absolute;
    min-width: 100%;
    left: 0;
    bottom: 0;
    height: 650px;
  }
  @media all and (max-width: 1024px){
    .abstract-1, .abstract-2, .abstract-3{
      display: none;
    }
  }
    @media all and (max-width: 768px) {
      & > .container{
        padding: 0 0 50px;
      }
      .heading-1{
        font-size: 3rem;
      }
      p{
        font-size: 2rem;
      }
      .button{
        display: flex;width: 100%;
        font-size: 2rem;
      }
      .primary-bg{
        display: none;
      }
      .primary-bg-mobile{
        display: block;
      }
    //.primary-bg {
    //  top: unset;
    //  bottom: 0;
    //  width: unset;
    //  height: 140vh;
    //}
  }
}
@media all and (max-width: 450px) {
  .welcome-page .dac_hero{
    background-position: bottom;
  }
  .dac_hero{
    background: url("../assets/images/IMG_4414.jpg") !important;
    background-size: cover !important;
  }
  .hero-content br{
    display: contents;
  }

  .welcome-page .mobilehead{
    margin-top: -131px;
  }
}
