.navbar.top {
  background-color: $mern-primary;

  &.has-shadow {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.3);
  }

  .navbar-brand-right {
    margin-left: auto;
    display: flex;
  }

  .navbar-end .navbar-item:not(.cta):hover,.navbar-end .navbar-item.is-active, {
    border-bottom: 2px solid $mern-hover;
    color: $mern-hover;
    background-color: $mern-primary;
  }

  .navbar-item {
    min-height: 70px;
    border-bottom: 2px solid transparent;
    transition: color 250ms ease;
    color: white;
    font-weight: 600;
  }

  .interaction-wrapper {
    display: flex;
  }
  @media all and (max-width: 768px){
    .navbar-menu .navbar-item {
      min-height: unset;
      text-align: center;
      font-size: 20px;
    }
    .interaction-wrapper {
      justify-content: center;
      margin: 20px;
    }
  }

  .navbar-item.is-hoverable:hover {
    border-bottom: 2px solid $mern-hover;
    color: $mern-hover;
    background-color: $mern-primary;
  }

  .navbar-item .dropdown-caret {
    border-top-color: $lightest-grey;
  }

  .navbar-item:not(.button).is-tab:hover {
    border-bottom: 1px solid $white;
  }

  .navbar-item:not(.button).is-tab.is-active {
    border-bottom: 3px solid $white;
    color: $white;
  }

  .navbar-item:focus-within {
    background-color: inherit;
    color: inherit;
  }

  .navbar-item img {
    max-height: none;
  }

  .profile-img {
    height: 32px;
    width: 32px;
    border: 1px solid $mern-hover;
    border-radius: 3px;
    max-height: none;
  }

  .button {
    font-weight: $weight-semibold;
  }

  .title {
    color: $white;
    font-weight: $weight-semibold;
  }

  .logo {
    color: $white;
    font: initial;
    font-size: 1.5rem;
    font-weight: 800;
  }

  .dropdown {
    .title {
      color: $black;
    }
  }
  .navbar-brand img{
    height: 55px;
  }
  .mobile-menu-footer{
    background: $dac-beige;
    padding: 1rem 3rem;
    color: $dac-red;
    font-size: 20px;
    font-weight: 600;
    height: 30%;
    display: none;
    flex-direction: column;
  }
  @media screen and (max-width: 1024px) {
    .navbar-menu {
      background-color: transparent;
      box-shadow: none;
    }
  }
  @media screen and (max-width: 768px) {
    &.active {
      display: flex;
      flex-direction: column;
      height: 100vh;
      max-height: 100vh;
      .mobile-menu-footer{
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.navbar-end{
  @media screen and (min-width: 1024px) {
    align-items: center;
  }
}
