.showcase-page {
  text-align: center;
  background-color: #EBD9BC;

  .title.heading-1{
    color: $dac-yellow;
  }
  .sub{
    color: $dac-red;
    font-weight: 600;
  }
  .year-selector-bar{
    background-color: $dac-yellow;
    color: $mern-hover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    p {font-size: 1.8rem; padding: 0 20px}
    & > div {
      font-size: 2rem;
      font-weight: 600;
      cursor: pointer;
      &.active{
        color: white;
      }
    }
    .title{
      color: $mern-hover;
      font-weight: 600;
    }
    @media all and (max-width: 768px){
      justify-content: space-evenly;
    }
  }
  .showcase-columns{
    padding: 0 0 10%;
    @media all and (max-width: 768px){
      & > .columns{
        flex-direction: column;
        //.column:first-child{
        //  order: 1;
        //}

      }

    }

    .showcase-box{
      background: rgba(255,255,255, 0.24);
      border-radius: 40px;
      display: inline-block;
      padding: 50px 25px;
      width: 300px;
      position: relative;
      .title, p{
        color: $dac-red;
      }
      .title{
        padding-top: 20px;
        font-weight: 600;
      }
      .showcase-icon{
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }
    .ranking{
      padding: 20px 0;
      font-size: 80px;
      font-weight: 600;
      color: #F7B21F;
    }
  }
  .title,p{
    color: #A22B38;
    font-weight: 600;
  }
  .preview{
    & > div{
      padding: 50px;
    }
    img{
      width: 200px;
    }
  }
}

.awards .title{
  margin-top: 45px;
margin-bottom: 65px;
}

.topthree{
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.activeyear2021{
  margin-bottom: 50px !important;
  margin-top: 45px !important;
}

.a2021{
  margin-left: -11%;
}

@media all and (max-width: 450px){
  .awards2021 p{
    font-size: 20px !important;
      margin-top: 17px !important;
  }
}
.videoTop{
  margin-top: 60px;;
}

