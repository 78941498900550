.main {
  margin-top: 70px;
  display: flex;
  flex: 1;
  & > div{
    width: 100%;
  }
}
#app > div{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
