.rules-page {
  background-color: $dac-beige;
  color: $dac-red;
  padding-bottom: 100px;
 & > .container{
   padding: 5%;
   z-index: 1;
 }
  .title{
    color: $dac-yellow;
    font-weight: 600;
    margin-bottom: 5px;
    &:not(.heading-1) {
      padding-top: 50px;
    }
  }
  .sub{
    color: $dac-red;
    padding-top: unset;
  }
}
