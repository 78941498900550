.register-page {
  .box {
    margin: 0 auto;
    max-width: 25rem;
  }
}

.submitreg{
  padding-right: 10px;
}
