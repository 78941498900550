.footer {
  background-color: #EFD9B7;
  position: relative;
  z-index: 3;
  padding: 3rem 1.5rem 3rem;
  .navbar {
    background-color: transparent;
  }

  .navbar-item img {
    max-height: unset;
  }

  .navbar-item, .navbar-link {
    color: $mern-primary;
    font-weight: 600;
    margin: 10px;

    &:hover {
      color: $mern-hover;
      background: initial;
    }
  }

  @media all and (max-width: 768px) {
    padding-bottom: 20px;
    .navbar-item, .navbar-link{
      font-size: 20px;
      margin: initial;
    }

    .footer-logo{
      height: 33px;
    }
  }

}
.bp-brand{
  position: relative;
  svg{
    position: absolute;
    bottom: -30px;
    width: 100%;
    z-index: 3;
    @media all and (max-width: 1024px) {
      bottom: -40px;
    }
  }
  img{
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 200px;
    right: 100px;
    @media all and (max-width: 1024px){
      bottom: 0;
      right: 0;
      width: 150px;
    }
  }
}
