.how-to-enter-page {
  background-color: $mern-primary;

  .container{
    padding: 5%;
  }
  .heading-1{
    color: $dac-yellow;
  }
  .column {

    p,ul{
      padding-left: 0px;
      color: #EBD9BC
    }
    a {
      display: flex;
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      margin: 10px 0;
    }
  }
}

.howtodiv{
  margin-top: 20px;
}

.downloadbrief{
  padding-right: 20px;
}



@media all and (max-width: 768px){
  .hideline{
    display: none;
  }
}
